import React from 'react';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import {
  AiFillDislike,
  AiFillLike,
  AiOutlineComment,
  AiOutlineDislike,
  AiOutlineLike,
  AiOutlineRetweet,
  AiOutlineShareAlt,
} from 'react-icons/ai';
import { useDispatch } from 'react-redux';
import { BsDot } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import postedAt from '../utils/postedAt';
import {
  asyncDownVoteThread,
  asyncNeutralizeThread,
  asyncUpVoteThread,
} from '../states/threads/action';

export default function ThreadItem({ authUser, thread, userCreated }) {
  const dispatch = useDispatch();

  const upVote = async () => {
    if (!authUser) return alert('You must be logged in to vote.');

    if (thread.upVotesBy.includes(authUser.id)) return dispatch(asyncNeutralizeThread(thread.id));

    return dispatch(asyncUpVoteThread(thread.id));
  };

  const downVote = async () => {
    if (!authUser) return alert('You must be logged in to vote.');

    if (thread.downVotesBy.includes(authUser.id)) return dispatch(asyncNeutralizeThread(thread.id));

    return dispatch(asyncDownVoteThread(thread.id));
  };

  return (
    <div className="flex items-start gap-3 px-4">
      <div className="flex w-16">
        <img src={userCreated.avatar} alt={userCreated.name} className="rounded-full" />
      </div>
      <div className="w-full">
        <Link to={`/thread/${thread.id}`}>
          <div className="mb-1 flex flex-wrap items-center gap-x-2">
            <span className="font-semibold">{userCreated.name}</span>
            <span className="text-gray-600">{userCreated.email}</span>
            <BsDot className="-ml-1 -mr-2 text-gray-600" />
            <span className="text-gray-600">{postedAt(thread.createdAt)}</span>
          </div>

          <p className="font-medium">{thread.title}</p>
          <div className="line-clamp-2">{parse(thread.body)}</div>
          <span className="text-sky-500">{`#${thread.category}`}</span>
        </Link>

        <div className="mt-2 flex w-full justify-between text-xl text-gray-500">
          <Link to={`/thread/${thread.id}`} className="flex items-center gap-1">
            <AiOutlineComment />
            <span className="text-base">{thread.totalComments}</span>
          </Link>
          <div
            onClick={upVote}
            aria-hidden="true"
            className="flex cursor-pointer items-center gap-1"
          >
            {authUser && thread.upVotesBy.includes(authUser.id) ? (
              <AiFillLike className="text-sky-500" />
            ) : (
              <AiOutlineLike />
            )}
            <span className="text-base">{thread.upVotesBy.length}</span>
          </div>
          <div
            onClick={downVote}
            aria-hidden="true"
            className="flex cursor-pointer items-center gap-1"
          >
            {authUser && thread.downVotesBy.includes(authUser.id) ? (
              <AiFillDislike className="text-sky-500" />
            ) : (
              <AiOutlineDislike />
            )}
            <span className="text-base">{thread.downVotesBy.length}</span>
          </div>
          <AiOutlineRetweet
            onClick={() => alert('Feature under development')}
            className="cursor-pointer"
          />
          <AiOutlineShareAlt
            onClick={() => alert('Feature under development')}
            className="cursor-pointer"
          />
        </div>
      </div>
    </div>
  );
}

ThreadItem.propTypes = {
  authUser: PropTypes.object,
  thread: PropTypes.object.isRequired,
  userCreated: PropTypes.object.isRequired,
};

ThreadItem.defaultProps = {
  authUser: null,
};
