import React from 'react';
import { useDispatch } from 'react-redux';
import { asyncRegisterUser } from '../../states/users/action';
import { asyncSetAuthUser } from '../../states/authUser/action';
import FormRegister from './FormRegister';

export default function Register() {
  const dispatch = useDispatch();

  const registerHandler = ({ name, email, password }) => {
    dispatch(asyncRegisterUser({ name, email, password })).then((user) => {
      if (user) dispatch(asyncSetAuthUser({ email, password }));
    });
  };

  return (
    <div className="flex w-full grow flex-col px-4 pt-4">
      <div className="mb-5 flex justify-center">
        <img src="/logo.svg" alt="" className="w-12" />
      </div>

      <p className="text-2xl font-semibold">
        To be able to use the application, please register your account first
      </p>

      <FormRegister registerHandler={registerHandler} />
    </div>
  );
}
