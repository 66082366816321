import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { IoMdClose } from 'react-icons/io';
import { useDispatch } from 'react-redux';
import { asyncAddThread } from '../states/threads/action';
import { asyncPopulateUsersAndThreads } from '../states/shared/action';

export default function ModalAddThread({ authUser, setShowModalAddThread }) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();

  const addThreadHandler = async ({ title, body, category = 'general' }) => {
    dispatch(asyncAddThread(title, body, category)).then(() =>
      dispatch(asyncPopulateUsersAndThreads()),
    );

    setShowModalAddThread(false);
    reset();
  };

  return (
    <div
      id="add-thread-modal"
      className="fixed top-0 z-50 flex h-screen w-screen max-w-lg flex-col bg-white sm:w-full"
    >
      <div className="flex items-center justify-between px-4 py-5">
        <IoMdClose
          onClick={() => {
            setShowModalAddThread(false);
            reset();
          }}
          className="cursor-pointer text-3xl text-slate-600"
        />
        <button
          type="submit"
          form="add-thread-form"
          className="rounded-full bg-sky-500 px-4 py-1.5 text-sm font-medium text-white"
        >
          Thread
        </button>
      </div>

      <hr />

      <div className="flex w-full grow flex-col px-4">
        <div className="mt-5 flex w-full items-start gap-2">
          <img src={authUser.avatar} alt={authUser.name} className="w-14 rounded-full" />
          <form
            onSubmit={handleSubmit(addThreadHandler)}
            id="add-thread-form"
            className="mt-1 flex w-full flex-col gap-5"
          >
            <div className="flex flex-col">
              <textarea
                type="text"
                placeholder="Type your title"
                {...register('title', { required: true })}
                className={`h-[10vh] w-full rounded-xl font-medium text-slate-600 ${
                  errors.title
                    ? 'border-red-500 focus:border-red-500 focus:ring-red-500'
                    : 'focus:border-sky-500 focus:ring-sky-500'
                }`}
              />
              {errors.title && (
                <span className="text-sm font-medium text-red-500">This field is required</span>
              )}
            </div>

            <div className="flex flex-col">
              <textarea
                type="text"
                placeholder="Type your thread"
                {...register('body', { required: true })}
                className={`h-[20vh] w-full rounded-xl font-medium text-slate-600 ${
                  errors.body
                    ? 'border-red-500 focus:border-red-500 focus:ring-red-500'
                    : 'focus:border-sky-500 focus:ring-sky-500'
                }`}
              />
              {errors.title && (
                <span className="text-sm font-medium text-red-500">This field is required</span>
              )}
            </div>

            <input
              type="text"
              placeholder="Type your category (optional)"
              {...register('category')}
              className="w-full rounded-xl font-medium text-slate-600"
            />
          </form>
        </div>
      </div>
    </div>
  );
}

ModalAddThread.propTypes = {
  authUser: PropTypes.object.isRequired,
  setShowModalAddThread: PropTypes.func.isRequired,
};
