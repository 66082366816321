import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { asyncPopulateUsersAndThreads } from '../states/shared/action';
import { asyncUnsetAuthUser } from '../states/authUser/action';
import FloatButton from '../components/FloatButton';
import ModalAddThread from '../components/ModalAddThread';
import ThreadItem from '../components/ThreadItem';
import UserCard from '../components/UserCard';

export default function ProfilePage() {
  const { threads = [], users = [], authUser } = useSelector((states) => states);
  const [myThreads, setMyThreads] = useState([]);
  const [recomendedUsers, setRecomendedUsers] = useState([]);
  const [showModalAddThread, setShowModalAddThread] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(asyncPopulateUsersAndThreads());
  }, [dispatch]);

  useEffect(() => {
    const myThreadsFilter = threads.filter((thread) => thread.ownerId === authUser.id);
    setMyThreads(myThreadsFilter);

    const otherUsers = users.filter((user) => user.id !== authUser.id);
    setRecomendedUsers(otherUsers.slice(0, 10));

    return () => {
      setMyThreads([]);
      setRecomendedUsers([]);
    };
  }, [users, threads]);

  const logoutHandler = () => {
    dispatch(asyncUnsetAuthUser());

    navigate('/');
  };

  return (
    <div className="flex grow flex-col items-center">
      <div className="relative z-10 flex w-full grow flex-col items-start gap-1">
        <div className="sticky top-0 w-full bg-white">
          <div className="h-24 w-full bg-sky-500" />
          <div className="relative w-full px-4">
            <img
              src={authUser.avatar}
              alt=""
              className="absolute -top-10 w-20 rounded-full border-[6px] border-white"
            />
            <div className="flex w-full items-end justify-end pt-5">
              <div className="flex gap-2">
                <button
                  type="button"
                  onClick={() => alert('This feature under development')}
                  className="rounded-full border border-sky-500 px-3 py-1.5 text-sm font-semibold text-sky-500"
                >
                  Settings
                </button>
                <button
                  type="button"
                  onClick={logoutHandler}
                  className="rounded-full border border-red-300 px-3 py-1.5 text-sm font-semibold text-red-400"
                >
                  Logout
                </button>
              </div>
            </div>

            <div className="flex flex-col items-start">
              <span className="text-xl font-bold">{authUser.name}</span>
              <span className="text-sm text-gray-500">{authUser.email}</span>
            </div>

            <div className="mt-2 flex gap-2">
              <p className="text-sm text-gray-500">
                <span className="text-base font-bold text-black">0</span> Following
              </p>
              <p className="text-sm text-gray-500">
                <span className="text-base font-bold text-black">0</span> Follower
              </p>
            </div>
          </div>

          <div className="mt-5 w-full bg-white">
            <div className="ml-4 flex w-14 flex-col items-center">
              <button type="button" className="font-semibold">
                Thread
              </button>
              <div className="h-0.5 w-full rounded-full bg-sky-500 " />
            </div>
            <hr className="w-full" />
            <hr className="w-full" />
          </div>
        </div>

        <div className="mt-5 w-full">
          {myThreads.length === 0 ? (
            <div className="mb-10 mt-5 text-center text-sm text-gray-600">
              You doesn&apos;t have thread
            </div>
          ) : (
            myThreads.map((thread) => {
              const userCreated = users.find((user) => user.id === thread.ownerId);

              return (
                <div key={thread.id} className="">
                  <ThreadItem authUser={authUser} thread={thread} userCreated={userCreated} />
                  <hr className="my-5" />
                </div>
              );
            })
          )}
        </div>

        <div className="relative mt-5 w-screen max-w-lg px-4 pb-16 sm:w-full">
          <div className="flex gap-4 overflow-x-auto">
            {recomendedUsers.length !== 0 &&
              recomendedUsers.map((user) => <UserCard user={user} key={user.id} />)}
          </div>
        </div>

        <FloatButton type="add thread" authUser={authUser} setShowModal={setShowModalAddThread} />
        {authUser && showModalAddThread ? (
          <ModalAddThread authUser={authUser} setShowModalAddThread={setShowModalAddThread} />
        ) : null}
      </div>
    </div>
  );
}
