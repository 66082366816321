import React from 'react';
import { useDispatch } from 'react-redux';
import FormLogin from './FormLogin';
import { asyncSetAuthUser } from '../../states/authUser/action';

export default function Login() {
  const dispatch = useDispatch();

  const loginHandler = ({ email, password }) => {
    dispatch(asyncSetAuthUser({ email, password }));
  };

  return (
    <div className="flex w-full grow flex-col px-4 pt-4">
      <div className="mb-5 flex justify-center">
        <img src="/logo.svg" alt="" className="w-12" />
      </div>

      <p className="text-2xl font-semibold">To login to the apps, input your email and password</p>
      <FormLogin loginHandler={loginHandler} />
    </div>
  );
}
