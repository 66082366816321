import React, { useEffect } from 'react';
import { Link, Navigate, Route, Routes } from 'react-router-dom';
import {
  BsBarChartFill,
  BsFillChatSquareQuoteFill,
  BsFillPersonCheckFill,
  BsFillPersonXFill,
} from 'react-icons/bs';
import LoadingBar from 'react-redux-loading-bar';
import { useDispatch, useSelector } from 'react-redux';

import { asyncPreloadProcess } from './states/isPreload/action';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import ThreadsPage from './pages/ThreadsPage';
import ThreadDetailPage from './pages/ThreadDetailPage';
import LeaderboardsPage from './pages/LeaderboardsPage';
import ProfilePage from './pages/ProfilePage';
import NotFoundPage from './pages/NotFoundPage';

export default function App() {
  const { authUser = null, isPreload = false } = useSelector((states) => states);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(asyncPreloadProcess());
  }, [dispatch]);

  if (isPreload) {
    return null;
  }

  return (
    <>
      <div className="fixed top-0 z-40 h-4 w-full">
        <LoadingBar style={{ backgroundColor: '#0ea5e9' }} />
      </div>
      <div className="mx-auto min-h-screen max-w-lg bg-white">
        <div className="fixed bottom-0 z-20 grid w-full max-w-lg grid-cols-3 border-t bg-white pb-1.5 pt-3 text-gray-700">
          <div className="flex justify-center">
            <Link to="/" className="flex flex-col items-center justify-center text-2xl">
              <BsFillChatSquareQuoteFill />
              <span className="text-sm font-medium">Threads</span>
            </Link>
          </div>
          <div className="flex justify-center">
            <Link to="/leaderboards" className="flex flex-col items-center justify-center text-2xl">
              <BsBarChartFill />
              <span className="text-sm font-medium">Leaderboards</span>
            </Link>
          </div>
          {authUser ? (
            <div className="flex justify-center">
              <Link to="/profile" className="flex flex-col items-center justify-center text-2xl">
                <BsFillPersonCheckFill />
                <span className="text-sm font-medium">Profile</span>
              </Link>
            </div>
          ) : (
            <div className="flex justify-center">
              <Link to="/login" className="flex flex-col items-center justify-center text-2xl">
                <BsFillPersonXFill />
                <span className="text-sm font-medium">Login</span>
              </Link>
            </div>
          )}
        </div>

        <div className="relative flex min-h-screen flex-col pb-24">
          <div className="flex grow flex-col">
            <Routes>
              <Route path="/" element={<ThreadsPage />} />
              <Route path="/thread/:threadId" element={<ThreadDetailPage />} />
              <Route path="/leaderboards" element={<LeaderboardsPage />} />
              <Route
                path="/profile"
                element={authUser ? <ProfilePage /> : <Navigate to="/login" replace />}
              />
              <Route
                path="/login"
                element={authUser ? <Navigate to="/" replace /> : <LoginPage />}
              />
              <Route
                path="/register"
                element={authUser ? <Navigate to="/" replace /> : <RegisterPage />}
              />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </div>
        </div>
      </div>
    </>
  );
}
