import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Register from '../components/Register';

export default function RegisterPage() {
  const { authUser = null } = useSelector((states) => states);
  const navigate = useNavigate();

  useEffect(() => {
    if (authUser) {
      navigate('/');
    }
  }, [authUser]);

  return <Register />;
}
