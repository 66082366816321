import React from 'react';
import PropTypes from 'prop-types';
import { BsFillChatDotsFill, BsPencilFill } from 'react-icons/bs';

export default function FloatButton({ type, authUser, setShowModal }) {
  return (
    <div className="fixed bottom-20 mx-auto flex w-full max-w-lg justify-end pr-4">
      <button
        type="button"
        id={type === 'add thread' ? 'add-thread' : 'add-comment'}
        onClick={
          authUser ? () => setShowModal(true) : () => alert(`You must be logged in to ${type}.`)
        }
        className="rounded-full bg-sky-500 p-4 text-xl text-white shadow-lg"
      >
        {type === 'add thread' && <BsPencilFill />}
        {type === 'add comment' && <BsFillChatDotsFill />}
      </button>
    </div>
  );
}

FloatButton.propTypes = {
  authUser: PropTypes.object,
  type: PropTypes.string.isRequired,
  setShowModal: PropTypes.func.isRequired,
};

FloatButton.defaultProps = {
  authUser: null,
};
