import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { asyncSetLeaderboards } from '../states/leaderboards/action';
import Header from '../components/Header';

export default function LeaderboardsPage() {
  const { leaderboards = [] } = useSelector((states) => states);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(asyncSetLeaderboards());
  }, [dispatch]);

  return (
    <div className="relative w-full">
      <div className="sticky top-0 flex flex-col bg-white">
        <Header />
      </div>

      <div className="pt-5">
        {leaderboards.map((leaderboard, i) => (
          <div key={leaderboard.user.id}>
            <div className="flex items-center justify-between px-4">
              <div className="flex items-center gap-3">
                <span>{`${i + 1}.`}</span>
                <img src={leaderboard.user.avatar} alt="" className="h-14 rounded-full" />
                <div className="">
                  <p className="font-medium">{leaderboard.user.name}</p>
                  <p className="text-sm text-gray-700">{leaderboard.user.email}</p>
                </div>
              </div>
              <span className="text-xl font-medium">{leaderboard.score}</span>
            </div>
            <hr className="my-3" />
          </div>
        ))}
      </div>
    </div>
  );
}
