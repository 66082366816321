import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

export default function HastagList({ threads, filterThreads, tagSelected }) {
  const [tagList, setTagList] = useState([]);
  const tagListFilter = [];

  useEffect(() => {
    threads.filter((thread) => {
      if (!tagListFilter.includes(thread.category)) tagListFilter.push(thread.category);

      return true;
    });

    setTagList(tagListFilter);
  }, []);

  return (
    <>
      <div className="flex gap-3 overflow-auto px-4 pt-4">
        {tagList.map((tag, i) => (
          <button
            type="button"
            onClick={() => {
              filterThreads(tag);
            }}
            key={i}
            className={`rounded-full border px-4 py-1 ${
              tagSelected === tag ? 'bg-slate-600 text-white' : ''
            }`}
          >
            #{tag}
          </button>
        ))}
      </div>
      {threads.length !== 0 && <hr className="mt-4" />}
    </>
  );
}

HastagList.propTypes = {
  threads: PropTypes.array.isRequired,
  filterThreads: PropTypes.func.isRequired,
  tagSelected: PropTypes.string.isRequired,
};
