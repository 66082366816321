import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

export default function FormRegister({ registerHandler }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onRegister = (values) => {
    registerHandler({ ...values });
  };

  return (
    <>
      <div className="grow pt-5">
        <form
          onSubmit={handleSubmit(onRegister)}
          id="register-form"
          className="flex flex-col gap-5"
        >
          <label htmlFor="name" className="flex flex-col">
            <span className="text-sm font-semibold text-slate-600">Name</span>
            <input
              type="text"
              {...register('name', { required: true })}
              placeholder="Name"
              className={`rounded-xl font-medium text-slate-600 ${
                errors.name
                  ? 'border-red-500 focus:border-red-500 focus:ring-red-500'
                  : 'focus:border-slate-600 focus:ring-slate-600'
              }`}
            />
            {errors.name && (
              <span className="text-sm font-medium text-red-500">This field is required</span>
            )}
          </label>

          <label htmlFor="email" className="flex flex-col">
            <span className="text-sm font-medium text-slate-600">Email</span>
            <input
              id="email"
              type="text"
              {...register('email', { required: true })}
              placeholder="Email"
              className={`rounded-xl font-medium text-slate-600 ${
                errors.email
                  ? 'border-red-500 focus:border-red-500 focus:ring-red-500'
                  : 'focus:border-slate-600 focus:ring-slate-600'
              }`}
            />
            {errors.email && (
              <span className="text-sm font-medium text-red-500">This field is required</span>
            )}
          </label>

          <label htmlFor="password" className="flex flex-col">
            <span className="text-sm font-semibold text-slate-600">Password</span>
            <input
              type="password"
              {...register('password', { required: true })}
              placeholder="Password"
              className={`rounded-xl font-medium text-slate-600 ${
                errors.password
                  ? 'border-red-500 focus:border-red-500 focus:ring-red-500'
                  : 'focus:border-slate-600 focus:ring-slate-600'
              }`}
            />
            {errors.password && (
              <span className="text-sm font-medium text-red-500">This field is required</span>
            )}
          </label>
        </form>
      </div>
      <hr className="my-4" />
      <div className="flex justify-between">
        <Link
          to="/login"
          className="rounded-full border border-sky-500 px-5 py-1.5 text-sm font-semibold text-sky-500"
        >
          Back to login
        </Link>
        <button
          type="submit"
          form="register-form"
          className="rounded-full bg-sky-500 px-5 py-1.5 text-sm font-semibold text-white"
        >
          Register
        </button>
      </div>
    </>
  );
}

FormRegister.propTypes = {
  registerHandler: PropTypes.func.isRequired,
};
