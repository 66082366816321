import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  AiFillDislike,
  AiFillLike,
  AiOutlineComment,
  AiOutlineDislike,
  AiOutlineLike,
  AiOutlineRetweet,
  AiOutlineShareAlt,
} from 'react-icons/ai';
import { BsDot } from 'react-icons/bs';
import {
  asyncDownVoteComment,
  asyncNeutralizeVoteComment,
  asyncSetThreadDetail,
  asyncUpVoteComment,
} from '../states/threadDetails/action';
import postedAt from '../utils/postedAt';
import FloatButton from '../components/FloatButton';
import {
  asyncDownVoteThread,
  asyncNeutralizeThread,
  asyncUpVoteThread,
} from '../states/threads/action';
import ModalAddComment from '../components/ModalAddComment';

export default function ThreadDetailPage() {
  const dispatch = useDispatch();

  const { threadDetail = null, authUser } = useSelector((states) => states);
  const { threadId } = useParams();
  const [showCommentModal, setShowCommentModal] = useState(false);

  useEffect(() => {
    dispatch(asyncSetThreadDetail(threadId));
  }, [dispatch]);

  const upVoteThread = async () => {
    if (!authUser) return alert('You must be logged in to vote.');

    if (threadDetail.upVotesBy.includes(authUser.id)) {
      return dispatch(asyncNeutralizeThread(threadId)).then(() =>
        dispatch(asyncSetThreadDetail(threadId)),
      );
    }

    return dispatch(asyncUpVoteThread(threadId)).then(() =>
      dispatch(asyncSetThreadDetail(threadId)),
    );
  };

  const downVoteThread = async () => {
    if (!authUser) return alert('You must be logged in to vote.');

    if (threadDetail.downVotesBy.includes(authUser.id)) {
      return dispatch(asyncNeutralizeThread(threadId)).then(() =>
        dispatch(asyncSetThreadDetail(threadId)),
      );
    }

    return dispatch(asyncDownVoteThread(threadId)).then(() =>
      dispatch(asyncSetThreadDetail(threadId)),
    );
  };

  const upVoteComment = async (commentId) => {
    if (!authUser) return alert('You must be logged in to vote.');

    const commentFilter = threadDetail.comments.find((comment) => comment.id === commentId);

    if (commentFilter.upVotesBy.includes(authUser.id)) {
      return dispatch(asyncNeutralizeVoteComment(threadId, commentId)).then(() =>
        dispatch(asyncSetThreadDetail(threadId)),
      );
    }

    return dispatch(asyncUpVoteComment(threadId, commentId)).then(() =>
      dispatch(asyncSetThreadDetail(threadId)),
    );
  };

  const downVoteComment = async (commentId) => {
    if (!authUser) return alert('You must be logged in to vote.');

    const commentFilter = threadDetail.comments.find((comment) => comment.id === commentId);

    if (commentFilter.downVotesBy.includes(authUser.id)) {
      return dispatch(asyncNeutralizeVoteComment(threadId, commentId)).then(() =>
        dispatch(asyncSetThreadDetail(threadId)),
      );
    }

    return dispatch(asyncDownVoteComment(threadId, commentId)).then(() =>
      dispatch(asyncSetThreadDetail(threadId)),
    );
  };

  return (
    <div className="w-full pb-16 pt-4">
      <div className="px-4">
        <p className="text-lg font-semibold">Thread</p>
      </div>
      <hr className="my-4" />

      {threadDetail && (
        <>
          <div className="px-4">
            <div className="flex items-center gap-3">
              <img
                src={threadDetail.owner.avatar}
                alt={threadDetail.owner.name}
                className="w-16 rounded-full"
              />
              <span className="font-semibold">{threadDetail.owner.name}</span>
            </div>

            <div className="mt-2">
              <p className="font-medium">{threadDetail.title}</p>
              <div className="">{parse(threadDetail.body)}</div>
              <p className="text-sky-500">{`#${threadDetail.category}`}</p>
              <p className="text-sm text-gray-600">{postedAt(threadDetail.createdAt)}</p>
            </div>

            <hr className="my-3" />
            <div className="flex gap-4 text-sm">
              <p>
                <span className="font-bold">{threadDetail.comments.length}</span> Comment
              </p>
              <p>
                <span className="font-bold">{threadDetail.upVotesBy.length}</span> Up Vote
              </p>
              <p>
                <span className="font-bold">{threadDetail.downVotesBy.length}</span> Down Vote
              </p>
            </div>
            <hr className="my-3" />

            <div className="mt-2 flex w-full justify-around text-2xl text-gray-500">
              <AiOutlineComment
                onClick={
                  authUser
                    ? () => setShowCommentModal(true)
                    : () => alert('You must be logged in to comment.')
                }
                className="cursor-pointer"
              />
              <button type="button" onClick={upVoteThread}>
                {authUser && threadDetail.upVotesBy.includes(authUser.id) ? (
                  <AiFillLike className="text-sky-500" />
                ) : (
                  <AiOutlineLike />
                )}
              </button>

              <button type="button" onClick={downVoteThread}>
                {authUser && threadDetail.downVotesBy.includes(authUser.id) ? (
                  <AiFillDislike className="text-sky-500" />
                ) : (
                  <AiOutlineDislike />
                )}
              </button>
              <AiOutlineRetweet
                onClick={() => alert('Feature under development')}
                className="cursor-pointer"
              />
              <AiOutlineShareAlt
                onClick={() => alert('Feature under development')}
                className="cursor-pointer"
              />
            </div>
          </div>
          <hr className="my-3" />

          {threadDetail.comments.map((comment) => (
            <div key={comment.id} className="">
              <div className="flex items-start gap-3 px-4">
                <div className="flex w-16">
                  <img
                    src={comment.owner.avatar}
                    alt={comment.owner.name}
                    className="rounded-full"
                  />
                </div>
                <div className="w-full">
                  <div className="mb-1 flex flex-wrap items-center gap-x-2">
                    <span className="font-semibold">{comment.owner.name}</span>
                    <BsDot className="-ml-1 -mr-2 text-gray-600" />
                    <span className="text-gray-600">{postedAt(comment.createdAt)}</span>
                  </div>

                  <div className="">{parse(comment.content)}</div>

                  <div className="mt-2 flex w-full justify-between text-xl text-gray-500">
                    <button
                      type="button"
                      onClick={() => upVoteComment(comment.id)}
                      className="flex cursor-pointer items-center gap-1"
                    >
                      {authUser && comment.upVotesBy.includes(authUser.id) ? (
                        <AiFillLike className="text-sky-500" />
                      ) : (
                        <AiOutlineLike />
                      )}
                      <span className="text-base">{comment.upVotesBy.length}</span>
                    </button>

                    <button
                      type="button"
                      onClick={() => downVoteComment(comment.id)}
                      className="flex cursor-pointer items-center gap-1"
                    >
                      {authUser && comment.downVotesBy.includes(authUser.id) ? (
                        <AiFillDislike className="text-sky-500" />
                      ) : (
                        <AiOutlineDislike />
                      )}
                      <span className="text-base">{comment.downVotesBy.length}</span>
                    </button>
                    <AiOutlineRetweet
                      onClick={() => alert('Feature under development')}
                      className="cursor-pointer"
                    />
                    <AiOutlineShareAlt
                      onClick={() => alert('Feature under development')}
                      className="cursor-pointer"
                    />
                  </div>
                </div>
              </div>
              <hr className="my-3" />
            </div>
          ))}
        </>
      )}

      <FloatButton type="add comment" authUser={authUser} setShowModal={setShowCommentModal} />
      {showCommentModal && authUser ? (
        <ModalAddComment
          authUser={authUser}
          threadDetail={threadDetail}
          setShowCommentModal={setShowCommentModal}
        />
      ) : null}
    </div>
  );
}
