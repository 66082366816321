import React from 'react';
import PropTypes from 'prop-types';

export default function UserCard({ user }) {
  return (
    <div
      className="flex w-32 flex-shrink-0 flex-col items-center justify-center rounded-xl border py-5"
      key={user.id}
    >
      <img src={user.avatar} alt={user.name} className="w-16 rounded-full" />
      <p className="w-28 truncate text-center text-sm font-bold">{user.name}</p>
      <p className="w-28 truncate text-center text-xs text-gray-500">{user.email}</p>
      <button
        type="button"
        onClick={() => alert('This feature under development')}
        className="mt-8 rounded-full bg-slate-600 px-4 py-1.5 text-sm font-semibold text-white"
      >
        Follow
      </button>
    </div>
  );
}

UserCard.propTypes = {
  user: PropTypes.object.isRequired,
};
