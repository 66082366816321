import axios from 'axios';
// const axios = require('axios');

const api = (() => {
  // const BASE_URL = `${import.meta.env.VITE_API_URL}/${import.meta.env.VITE_API_VERSION}`;
  const BASE_URL = 'https://forum-api.dicoding.dev/v1';

  function getAccessToken() {
    return localStorage.getItem('accessToken');
  }

  function putAccessToken(token) {
    localStorage.setItem('accessToken', token);
  }

  // FETCH WITH AUTH
  async function fetchWithAuth(url, options = {}) {
    return axios({
      ...options,
      url,
      data: {
        ...options.data,
      },
      headers: {
        ...options.headers,
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
  }

  // REGISTER
  async function register({ name, email, password }) {
    try {
      const {
        data: {
          data: { user },
        },
      } = await axios.post(`${BASE_URL}/register`, {
        name,
        email,
        password,
      });

      return user;
    } catch (error) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error(error.message);
      }
    }
  }

  // LOGIN
  async function login({ email, password }) {
    try {
      const {
        data: {
          data: { token },
        },
      } = await axios.post(`${BASE_URL}/login`, {
        email,
        password,
      });

      return token;
    } catch (error) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error(error.message);
      }
    }
  }

  // GET ALL USERS
  async function getUsers() {
    try {
      const {
        data: {
          data: { users },
        },
      } = await axios.get(`${BASE_URL}/users`);

      return users;
    } catch (error) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error(error.message);
      }
    }
  }

  // GET OWN PROFILE
  async function getOwnProfile() {
    try {
      const {
        data: {
          data: { user },
        },
      } = await fetchWithAuth(`${BASE_URL}/users/me`, { method: 'GET' });

      return user;
    } catch (error) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error(error.message);
      }
    }
  }

  // GET ALL THREADS
  const getThreads = async () => {
    try {
      const {
        data: {
          data: { threads },
        },
      } = await axios.get(`${BASE_URL}/threads`);

      return threads;
    } catch (error) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error(error.message);
      }
    }
  };

  // CREATE THREAD
  const createThread = async ({ title, body, category }) => {
    try {
      const {
        data: {
          data: { thread },
        },
      } = await fetchWithAuth(`${BASE_URL}/threads`, {
        method: 'POST',
        data: { title, body, category },
      });

      return thread;
    } catch (error) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error(error.message);
      }
    }
  };

  // GET DETAIL THREAD
  const getThreadDetail = async (threadId) => {
    try {
      const {
        data: {
          data: { detailThread },
        },
      } = await axios.get(`${BASE_URL}/threads/${threadId}`);

      return detailThread;
    } catch (error) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error(error.message);
      }
    }
  };

  // UP VOTE THREAD
  const upVoteThread = async (threadId) => {
    try {
      const {
        data: {
          data: { vote },
        },
      } = await fetchWithAuth(`${BASE_URL}/threads/${threadId}/up-vote`, { method: 'POST' });

      return vote;
    } catch (error) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error(error.message);
      }
    }
  };

  // DOWN VOTE THREAD
  const downVoteThread = async (threadId) => {
    try {
      const {
        data: {
          data: { vote },
        },
      } = await fetchWithAuth(`${BASE_URL}/threads/${threadId}/down-vote`, { method: 'POST' });

      return vote;
    } catch (error) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error(error.message);
      }
    }
  };

  // NEUTRAL VOTE THREAD
  const neutralizeVoteThread = async (threadId) => {
    try {
      const {
        data: {
          data: { vote },
        },
      } = await fetchWithAuth(`${BASE_URL}/threads/${threadId}/neutral-vote`, { method: 'POST' });

      return vote;
    } catch (error) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error(error.message);
      }
    }
  };

  // CREATE COMMENT
  const createComment = async ({ threadId, content }) => {
    try {
      const {
        data: {
          data: { comment },
        },
      } = await fetchWithAuth(`${BASE_URL}/threads/${threadId}/comments`, {
        method: 'POST',
        data: { content },
      });

      return comment;
    } catch (error) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error(error.message);
      }
    }
  };

  // UP VOTE COMMENT
  const upVoteComment = async (threadId, commentId) => {
    try {
      const {
        data: {
          data: { vote },
        },
      } = await fetchWithAuth(`${BASE_URL}/threads/${threadId}/comments/${commentId}/up-vote`, {
        method: 'POST',
      });

      return vote;
    } catch (error) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error(error.message);
      }
    }
  };

  // DOWN VOTE COMMENT
  const downVoteComment = async (threadId, commentId) => {
    try {
      const {
        data: {
          data: { vote },
        },
      } = await fetchWithAuth(`${BASE_URL}/threads/${threadId}/comments/${commentId}/down-vote`, {
        method: 'POST',
      });

      return vote;
    } catch (error) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error(error.message);
      }
    }
  };

  // NEUTRAL VOTE COMMENT
  const neutralizeVoteComment = async (threadId, commentId) => {
    try {
      const {
        data: {
          data: { vote },
        },
      } = await fetchWithAuth(
        `${BASE_URL}/threads/${threadId}/comments/${commentId}/neutral-vote`,
        {
          method: 'POST',
        },
      );

      return vote;
    } catch (error) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error(error.message);
      }
    }
  };

  // GET LEADERBOARDS
  const getLeaderboards = async () => {
    try {
      const {
        data: {
          data: { leaderboards },
        },
      } = await axios(`${BASE_URL}/leaderboards`);

      return leaderboards;
    } catch (error) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error(error.message);
      }
    }
  };

  return {
    getAccessToken,
    putAccessToken,
    getUsers,
    login,
    register,
    getOwnProfile,
    createThread,
    getThreads,
    getThreadDetail,
    upVoteThread,
    downVoteThread,
    neutralizeVoteThread,
    createComment,
    upVoteComment,
    downVoteComment,
    neutralizeVoteComment,
    getLeaderboards,
  };
})();

export default api;
