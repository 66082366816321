import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { asyncPopulateUsersAndThreads } from '../states/shared/action';
import ModalAddThread from '../components/ModalAddThread';
import FloatButton from '../components/FloatButton';
import ThreadItem from '../components/ThreadItem';
import Header from '../components/Header';
import HastagList from '../components/HastagList';

export default function ThreadsPage() {
  const { threads = [], users = [], authUser } = useSelector((states) => states);
  const [threadsFilter, setThreadsFilter] = useState([]);
  const [tagSelected, setTagSelected] = useState('');
  const [showModalAddThread, setShowModalAddThread] = useState(false);

  const dispatch = useDispatch();

  const filterThreads = (tag) => {
    let tagUsed = tag;

    if (tag === tagSelected) tagUsed = '';

    if (tagUsed === '') {
      setThreadsFilter(threads);
    } else {
      const resultFilter = threads.filter((thread) => thread.category === tagUsed);
      setThreadsFilter(resultFilter);
    }

    setTagSelected(tagUsed);
  };

  useEffect(() => {
    dispatch(asyncPopulateUsersAndThreads());
  }, [dispatch]);

  useEffect(() => {
    filterThreads('');
  }, [threads]);

  return (
    <div className="relative w-full">
      <div className="sticky top-0 flex flex-col bg-white">
        <Header />
        {threads.length !== 0 && (
          <HastagList threads={threads} filterThreads={filterThreads} tagSelected={tagSelected} />
        )}
      </div>

      <div className="mb-16 pt-4">
        {threadsFilter.map((thread) => {
          const userCreated = users.find((user) => user.id === thread.ownerId);

          return (
            <div key={thread.id} className="">
              <ThreadItem authUser={authUser} thread={thread} userCreated={userCreated} />
              <hr className="my-5" />
            </div>
          );
        })}
      </div>

      <FloatButton type="add thread" authUser={authUser} setShowModal={setShowModalAddThread} />
      {authUser && showModalAddThread ? (
        <ModalAddThread
          authUser={authUser}
          setShowModalAddThread={setShowModalAddThread}
          filterThreads={filterThreads}
        />
      ) : null}
    </div>
  );
}
