import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { IoMdClose } from 'react-icons/io';
import { asyncAddComment, asyncSetThreadDetail } from '../states/threadDetails/action';

export default function ModalAddComment({ authUser, threadDetail, setShowCommentModal }) {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const commentHandler = ({ comment }) => {
    dispatch(asyncAddComment(threadDetail.id, comment));
    dispatch(asyncSetThreadDetail(threadDetail.id));

    setShowCommentModal(false);
    reset();
  };

  return (
    <div className="fixed top-0 z-50 flex h-screen w-screen max-w-lg flex-col bg-white sm:w-full">
      <div className="flex items-center justify-between px-4 py-5">
        <IoMdClose
          onClick={() => {
            setShowCommentModal(false);
            reset();
          }}
          className="cursor-pointer text-3xl text-slate-600"
        />
        <button
          type="submit"
          form="comment-form"
          className="rounded-full bg-sky-500 px-4 py-1.5 text-sm font-medium text-white"
        >
          Comment
        </button>
      </div>

      <hr />

      <div className="flex w-full grow flex-col px-4">
        <p className="ml-7 border-l-2 py-2 pl-8">
          Replying <span className="font-bold">{threadDetail.owner.name}</span>
        </p>
        <div className="flex w-full items-start gap-2">
          <img src={authUser.avatar} alt={authUser.name} className="w-14 rounded-full" />
          <form
            onSubmit={handleSubmit(commentHandler)}
            id="comment-form"
            className="mt-1 flex w-full flex-col"
          >
            <textarea
              type="text"
              placeholder="Type your comment"
              {...register('comment', { required: true })}
              className={`h-[50vh] w-full rounded-xl font-medium text-slate-600 ${
                errors.comment
                  ? 'border-red-500 focus:border-red-500 focus:ring-red-500'
                  : 'focus:border-sky-500 focus:ring-sky-500'
              }`}
            />
            {errors.comment && (
              <span className="text-sm font-medium text-red-500">This field is required</span>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}

ModalAddComment.propTypes = {
  authUser: PropTypes.object.isRequired,
  threadDetail: PropTypes.object.isRequired,
  setShowCommentModal: PropTypes.func.isRequired,
};
